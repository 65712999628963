<template>
  <div class="main-div">
    <div class="form-div">
      <VasionInput
        v-model="modifyUser.userName"
        title="Username"
        class="input-style"
        inputType="top-white"
        required
        :isInErrorState="formFieldErrors.username"
        @input="val => val ? clearFieldError('username') : null"
      />

      <VasionInput
        v-if="displayPassword"
        v-model="userPassword"
        title="Password"
        class="input-style"
        inputType="top-white"
        specialType="password"
        :canToggleShowPassword="false"
        required
        :isInErrorState="formFieldErrors.password"
        @input="val => val ? clearFieldError('password') : null"
        @blur="passwordInputOnBlur"
        @focus="passwordInputOnFocus"
      />

      <div class="name-section">
        <VasionInput
          v-model="modifyUser.firstName"
          title="First Name"
          class="input-style first-name-input"
          inputType="top-white"
          required
          :isInErrorState="formFieldErrors.firstName"
          @input="val => val ? clearFieldError('firstName') : null"
        />
        <VasionInput
          v-model="modifyUser.lastName"
          title="Last Name"
          class="input-style last-name-input"
          inputType="top-white"
        />
      </div>

      <VasionInput
        v-model="modifyUser.middleName"
        title="Middle Name"
        class="input-style"
        inputType="top-white"
      />

      <div class="email-phone-section">
        <VasionInput
          v-model="modifyUser.email"
          title="Email"
          class="input-style email-input"
          inputType="top-white"
        />
        <VasionInput
          v-model="modifyUser.mobilePhone"
          title="Mobile"
          class="input-style mobile-input"
          inputType="top-white"
        />
      </div>

      <div class="dept-section">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedDepartment"
          :dataArray="departmentList"
          title="Department"
          class="droplist-style department-drop"
          :type="isAdminUser ? 'add-and-delete': 'plain-list'"
          displayName="sName"
          valueName="sName"
          :required="true"
          :showSearchField="false"
          :showAddItem="true"
          :addItemText="'New Department'"
          :forceOpen="forceOpenDepartmentDroplist"
          @addItemClicked="showAddDepartment = true"
          @deleteClicked="deleteDepartmentClicked"
        >
          {{ slotItem.item.sName }}
        </VasionDropList>
      </div>

      <div v-if="isAdminUser" class="md-layout">
        <VasionCheckbox
          id="dept-admin"
          name="dept-admin"
          :checked="isModUserDeptAdmin"
          @change="toggleDepartmentAdmin()"
        >
          Department Admin
        </VasionCheckbox>
      </div>
      <div v-if="isAdminUser && isModUserDeptAdmin" class="dept-admin-section">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedAdminDepartments"
          :dataArray="departmentList"
          :multiSelectInitialValue="selectedAdminDepartments"
          title="Department Admin"
          class="droplist-style department-drop"
          type="check-list-search"
          displayName="sName"
          valueName="iID"
          openAbove
        >
          {{ slotItem.item.sName }}
        </VasionDropList>
      </div>

      <div class="misc-section">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedLoginType"
          :dataArray="loginTypeList"
          title="Login Type"
          class="droplist-style login-type-drop"
          type="plain-list"
          displayName="sName"
          valueName="sName"
          openAbove
        >
          {{ slotItem.item.sName }}
        </VasionDropList>
      </div>

      <div class="md-layout">
        <div class="checkbox-div">
          <VasionCheckbox
            id="active-user"
            name="active-user"
            :checked="modifyUser.active"
            @change="toggleActiveUser()"
          >
            Active
          </VasionCheckbox>
        </div>
        <div
          v-if="!currentUserIsDepartmentAdmin"
          class="checkbox-div"
        >
          <VasionCheckbox
            id="admin-user"
            name="admin-user"
            :checked="modifyUser.admin"
            @change="toggleAdminUser()"
          >
            Admin
          </VasionCheckbox>
        </div>
      </div>

      <div class="bottom-div">
        <VasionButton id="cancel-button" :classProp="'primary-light'" @vasionButtonClicked="cancelClick()">
          CANCEL
        </VasionButton>
        <VasionButton id="ok-button" :classProp="'primary'" @vasionButtonClicked="okClick()">
          OK
        </VasionButton>
      </div>
    </div>

    <AddDepartmentModal
      :show="showAddDepartment"
      @close="showAddDepartment = false"
      @departmentAdded="departmentAdded"
    />

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showDeleteDepartmentConfirmation" :md-click-outside-to-close="false">
      <VasionConfirmationDialog
        :message="deleteDepartmentConfirmationMessage"
        @noButtonClick="showDeleteDepartmentConfirmation=false"
        @yesButtonClick="deleteDepartment"
      />
    </md-dialog>

    <VasionSnackbar
      :showSnackbarBool.sync="snackbar.show"
      :snackbarImage="snackbar.image"
      :snackbarSubTitle="snackbar.subtitle"
      :snackbarTitle="snackbar.title"
    />
  </div>
</template>

<script>
import AddDepartmentModal from '@/components/security/AddDepartmentModal.vue';
const PasswordPlaceholder = '790bbf9a16b84c6bb243'

export default {
  name: 'ConfigureUser',
  components: {
    AddDepartmentModal
  },
  props: {
    modifyUserId: {
      type: Number,
      default: 0,
      required: false,
    },
    currentUser: {
      type: Object,
      default: () => { return {
        adminForDepartments: null,
        admin: true,
        active: true,
        department: null,
        email: "",
        firstName: "",
        lastName: "",
        loginType: null,
        middleName: "",
        mobilePhone: "",
        passwordIsSet: true,
        userName: "",
        userID: null,
      }},
      required: true,
    }
  },
  data: function () {
    return {
      forceOpenDepartmentDroplist: false,
      formFieldErrors: {
        username: false,
        password: false,
        firstName: false,
      },
      isModUserDeptAdmin: false,
      modifyUser: {},
      selectedDepartment: {
        iID: 0,
        sName: '',
      },
      selectedAdminDepartments: [],
      selectedDepartmentForDelete: null,
      selectedLoginType: {
        iID: 0,
        sName: '',
      },
      showAddDepartment: false,
      showDeleteDepartmentConfirmation: false,
      snackbar: {
        show: false,
        image: false,
        subtitle: '',
        title: ''
      },
      userPassword: ''
    }
  },
  computed: {
    currentUserIsDepartmentAdmin() { return this.currentUser?.adminForDepartments?.length > 0 && !this.currentUser?.admin },
    deleteDepartmentConfirmationMessage() { return `Are you sure you want to delete this department: '${this.selectedDepartmentForDelete?.sName}'?` },
    departmentList() { return this.$store.state.security.departmentList },
    displayPassword() { return !(this.currentUserIsDepartmentAdmin && this.modifyUser.admin) },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    loginTypeList() { return this.$store.state.security.loginTypeList },
  },
  mounted: async function () {
    this.modifyUser = {
      admin: false,
      active: true,
      department: 1,
      email: '',
      firstName: '',
      lastName: '',
      loginType: 1,
      middleName: '',
      mobilePhone: '',
      password: '',
      userName: '',
      passwordIsSet: false
    }

    if (this.departmentList.length > 0) {
      this.selectedDepartment = this.departmentList[0]
    }

    if (this.loginTypeList.length > 0) {
      this.selectedLoginType = this.loginTypeList.find((x) => {
        return x.sName.toLowerCase() == "vasion login"
      })
    }

    if (this.modifyUserId <= 0) {
      return
    }

    this.modifyUser = await this.$store.dispatch('security/getUserDetails', this.modifyUserId)
    this.isModUserDeptAdmin = this.modifyUser.adminForDepartments && this.modifyUser.adminForDepartments.length > 0
    this.selectedAdminDepartments = this.isModUserDeptAdmin ? this.departmentList.filter(d => this.modifyUser.adminForDepartments.includes(d.iID)) : []

    if (this.modifyUser) {
      this.userPassword = this.modifyUser.passwordIsSet ? PasswordPlaceholder : ''
    }

    const foundDepartment = this.departmentList.find((department) => {
      return department.iID === this.modifyUser.department
    })

    if (foundDepartment) {
      this.selectedDepartment = foundDepartment
    }

    const foundLoginType = this.loginTypeList.find((loginType) => {
      return loginType.iID === this.modifyUser.loginType
    })

    if (foundLoginType) {
      this.selectedLoginType = foundLoginType
    }
  },
  methods: {
    cancelClick() {
      this.$emit('cancel-click')
    },
    clearFieldError(field) {
      this.formFieldErrors[field] = false
    },
    clearFieldErrors() {
      Object.keys(this.formFieldErrors).map(key => this.formFieldErrors[key] = false)
    },
    async okClick() {
      this.clearFieldErrors()
      let errorText = ''

      if (!this.modifyUser.userName) {
        this.formFieldErrors.username = true
      } else if (this.modifyUser.userName.trim() === '') {
        errorText += 'Username cannot only include spaces.\n'
      }

      if (this.displayPassword) {
        if (!this.userPassword) {
          this.formFieldErrors.password = true
        } else if (this.userPassword.trim() === '') {
          errorText += 'Password cannot only include spaces.\n'
        }
      }

      if (!this.modifyUser.firstName) {
        this.formFieldErrors.firstName = true
      } else if (this.modifyUser.firstName.trim() === '') {
        errorText += 'First Name cannot only include spaces.\n'
      }

      if((this.selectedDepartment?.iID ?? 0) === 0) {
        errorText += 'Please select a department.\n'
      }

      if (Object.values(this.formFieldErrors).some(x => x)) {
        errorText += 'Please fill out all required fields.'
      }

      if (errorText) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: errorText,
        }
        return
      }

      this.modifyUser.department = this.selectedDepartment.iID
      if (this.isAdminUser) {
        if (this.isModUserDeptAdmin && this.selectedAdminDepartments.length > 0) {
          this.modifyUser.adminForDepartments = this.selectedAdminDepartments.map(dept => dept.iID)
        } else {
          this.modifyUser.adminForDepartments = null
        }
      }

      this.modifyUser.loginType = this.selectedLoginType && this.selectedLoginType.iID > 0 ? this.selectedLoginType.iID : 1

      this.modifyUser.password = this.userPassword == PasswordPlaceholder ? '' : this.userPassword;

      const saveResult = await this.$store.dispatch('security/saveUserDetails', this.modifyUser)

      if (saveResult?.ResultMessage) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: 'Unable to save User Information.' + saveResult.ResultMessage
        }
        return
      } else if (!saveResult || !saveResult.userID) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: 'Unable to save User Information. Check logs.'
        }
        return
      }

      this.$emit('ok-click')
    },
    deleteDepartmentClicked(department){
      this.selectedDepartmentForDelete = department
      this.showDeleteDepartmentConfirmation = true
    },
    async deleteDepartment() {
      const department = this.selectedDepartmentForDelete
      const response = await this.$store.dispatch('security/deleteDepartment', { Value: department.iID.toString() })
      if (response?.Value?.length > 0 || response?.ResultStatus === 2) {
        this.snackbar = {
          show: true,
          image: false,
          title: 'Error',
          subtitle: response?.Value ?? `Error deleting department '${department.sName}'`
        }
      }
      else {
        this.snackbar = {
          show: true,
          image: true,
          title: 'Success',
          subtitle: `Department '${department.sName}' deleted`
        }
        if(this.selectedDepartment.iID === department.iID) {
          this.selectedDepartment = {
            iID: 0,
            sName: '',
          }
        }
        await this.$store.dispatch('security/loadDepartmentList')
      }

      this.showDeleteDepartmentConfirmation = false
      this.selectedDepartmentForDelete = null
    },
    departmentAdded(departmentName){
      const department = this.departmentList.find(x => x.sName === departmentName)
      if (department) {
        this.selectedDepartment = department
      }
      this.forceOpenDepartmentDroplist = true
      this.$nextTick(() => {
        this.forceOpenDepartmentDroplist = false
      })
    },
    passwordInputOnBlur() {
      if (this.modifyUser.passwordIsSet && this.userPassword === '') {
        this.userPassword = PasswordPlaceholder
      }
    },
    passwordInputOnFocus() {
      if (this.userPassword === PasswordPlaceholder) {
        this.userPassword = ''
      }
    },
    toggleActiveUser() {
      this.modifyUser.active = !this.modifyUser.active
    },
    toggleAdminUser() {
      this.modifyUser.admin = !this.modifyUser.admin
    },
    toggleDepartmentAdmin() {
      this.isModUserDeptAdmin = !this.isModUserDeptAdmin
    },
  },
}
</script>

<style lang="scss" scoped>
  .main-div {
    width: 480px;
    padding-top: 24px;
  }

  .form-div {
    display: flex;
    flex-direction: column;

    .name-section {
      display: flex;

      .first-name-input {
        flex: 1;
        margin-right: 12px;
      }
      .last-name-input {
        flex: 1;
      }
    }

    .email-phone-section {
      display: flex;
      .email-input {
        flex: 3;
        margin-right: 12px;
      }
      .mobile-input {
        flex: 2;
      }
    }

    .dept-section {
      display: flex;

      .department-drop {
        flex: 1;
      }
    }
    .dept-admin-section {
      display: flex;

      .department-drop {
        flex: 1;
      }
    }
    .misc-section {
      display: flex;

      .login-type-drop {
        flex: 1;
      }
    }
  }

  .bottom-div {
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .checkbox-div {
    margin-top: 16px;
    padding-right: 10px;
  }

  .input-style, .droplist-style {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #vasion-confirmation-div {
    z-index: 1001;
  }
</style>
