<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>User Management</h1>
      <div class="btns-container">
        <VasionButton
          v-show="selectedTabName === 'internal users' && ldapServerIp !== ''"
          :classProp="'secondary-grey'"
          @vasionButtonClicked="showImportLdapUsersDialog = true"
        >
          Import Users
        </VasionButton>
        <VasionButton
          v-show="selectedTabName === 'internal users'"
          class="last-btn"
          classProp="primary"
          @vasionButtonClicked="checkUserLimit()"
        >
          New Internal User
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="tab-button-div">
        <VasionButton
          v-if="!currentUserIsDepartmentAdmin && !isLoading"
          id="internal-users-tab-button"
          :classProp="internalUsersTabClass"
          @vasionButtonClicked="updateSelectedTab('internal users')"
        >
          Internal Users
        </VasionButton>
        <VasionButton
          v-if="!currentUserIsDepartmentAdmin && !isLoading"
          id="external-users-tab-button"
          :classProp="externalUsersTabClass"
          @vasionButtonClicked="updateSelectedTab('external users')"
        >
          External Users
        </VasionButton>
        <VasionButton
          v-if="isAdminUser && !isLoading"
          id="active-users-tab-button"
          :classProp="activeUsersTabClass"
          @vasionButtonClicked="updateSelectedTab('active users')"
        >
          Active Users
        </VasionButton>
      </div>
      <div v-show="selectedTabName === 'internal users'">
        <div class="md-layout search-row">
          <VasionInput
            id="search-users"
            v-model="UserSearchInput"
            v-debounce:300ms="userSearchList"
            name="search-users"
            class="search-field"
            placeholder="Search Users..."
            :width="'250'"
            inputType="search-white"
          />

          <VasionButton
            v-if="canConfigureUsers"
            id="default-temp-password-button"
            :classProp="'secondary'"
            class="top-button-position"
            @vasionButtonClicked="showDefaultPasswordPrompt"
          >
            Default Temp Password
          </VasionButton>
          <md-menu
            v-if="canExportUsers"
            md-align-trigger
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            class="md-menu-export"
          >
            <md-button
              id="btn-export-users-list"
              md-menu-trigger
              class="v-btn-primary export-margin"
              :disabled="false"
            >
              Export
              <VasionArrowDropDownIcon />
            </md-button>
            <md-menu-content class="dropdown-list-container">
              <md-menu-item @click="exportUsersList('CSV')">
                CSV
              </md-menu-item>
              <md-menu-item @click="exportUsersList('PDF')">
                PDF
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
        <div v-if="usersLoading">
          <!-- Show white space while waiting for data to load -->
        </div>
        <div v-else-if="localUserList && tableData.Columns" class="users-table-container vasion-html-table-default">
          <VasionTable
            :headerColumns="tableData.Columns.Values"
            :tableRows="tableData.Rows.Values"
            :hideColumns="hiddenColumns"
            :ellipsisButtonConfig="ellipsisButtonConfig"
            :supportSorting="true"
            @vasion-column-sort="sortColumns"
            @ellipsis-button-clicked="ellipsisButtonClicked"
            @vasion-selection="rowClickHandler"
          />
        </div>
      </div>
      <div v-show="selectedTabName === 'external users'">
        <div id="external-users-search-row" class="md-layout search-row">
          <VasionInput
            id="search-external-users"
            v-model="externalUserSearchInput"
            v-debounce:300ms="userSearchList"
            name="search-external-users"
            class="search-field"
            placeholder="Search Users ..."
            :width="'250'"
            inputType="search-white"
          />
          <md-menu
            v-if="canExportUsers"
            md-align-trigger
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            class="md-menu-export"
          >
            <md-button
              id="btn-export-users-list"
              md-menu-trigger
              class="v-btn-primary export-margin"
              :disabled="false"
            >
              Export
              <VasionArrowDropDownIcon />
            </md-button>
            <md-menu-content class="dropdown-list-container">
              <md-menu-item @click="exportUsersList('CSV')">
                CSV
              </md-menu-item>
              <md-menu-item @click="exportUsersList('PDF')">
                PDF
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>

        <div v-if="usersLoading">
          <!-- Show white space while waiting for data to load -->
        </div>
        <div v-else-if="localUserList && tableData.Columns" class="users-table-container vasion-html-table-default">
          <VasionTable
            :headerColumns="tableData.Columns.Values"
            :tableRows="tableData.Rows.Values"
            :hideColumns="hiddenColumnsExternal"
            :supportSorting="true"
            @vasion-column-sort="sortColumns"
          />
        </div>
      </div>
      <div v-show="selectedTabName === 'active users'">
        <div id="active-users-search-row" class="md-layout search-row">
          <VasionInput
            id="search-active-users"
            v-model="activeUserSearchInput"
            v-debounce:300ms="activeUserSearchList"
            name="search-active-users"
            class="search-field"
            placeholder="Search Users ..."
            :width="'250'"
            inputType="search-white"
          />
          <md-menu
            v-if="canExportUsers"
            md-align-trigger
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            class="md-menu-export"
          >
            <md-button
              id="btn-export-users-list"
              md-menu-trigger
              class="v-btn-primary export-margin"
              :disabled="false"
            >
              Export
              <VasionArrowDropDownIcon />
            </md-button>
            <md-menu-content class="dropdown-list-container">
              <md-menu-item @click="exportUsersList('CSV')">
                CSV
              </md-menu-item>
              <md-menu-item @click="exportUsersList('PDF')">
                PDF
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>

        <div v-if="usersLoading">
          <!-- Show white space while waiting for data to load -->
        </div>
        <div v-else-if="localActiveUserList && tableDataActive.Columns" class="users-table-container vasion-html-table-default">
          <VasionTable
            :headerColumns="tableDataActive.Columns.Values"
            :tableRows="tableDataActive.Rows.Values"
            :hideColumns="hiddenColumnsActive"
            :supportSorting="true"
            :ellipsisButtonConfig="ellipsisButtonConfig"
            @vasion-column-sort="sortColumnsActive"
            @ellipsis-button-clicked="ellipsisButtonClickedActive"
          />
        </div>
      </div>
    </div>
    <VasionGeneralModal
      id="configure-user-dialog"
      modalType="slot"
      :modalTitle="configureUserTitle"
      :sync="showConfigureUserDialog"
      :hideButtons="true"
      showTopRightCloseButton
      @noButtonClick="cancelConfigureUserClick"
    >
      <ConfigureUser
        :modify-user-id="selectedUserID"
        :currentUser="currentUser"
        @ok-click="okConfigureUserClick"
        @cancel-click="cancelConfigureUserClick"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      id="import-users-dialog"
      modalType="slot"
      :modalTitle="'Import Users'"
      :sync="showImportLdapUsersDialog"
      :hideButtons="true"
      showTopRightCloseButton
      @noButtonClick="showImportLdapUsersDialog = false"
    >
      <ImportLdapUsers
        @cancelBtnClick="showImportLdapUsersDialog = false"
        @successfulLdapUserImport="ldapUsersSuccessfullyImported"
      />
    </VasionGeneralModal>

    <md-dialog id="confirm-user-delete" :md-active.sync="showDeleteDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="deletePromptMessage" @noButtonClick="cancelDeleteClick()" @yesButtonClick="okDeleteClick()" />
    </md-dialog>

    <md-dialog id="confirm-user-email" :md-active.sync="showEmailDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="emailPromptMessage" @noButtonClick="cancelEmailClick()" @yesButtonClick="okEmailClick()" />
    </md-dialog>
    <md-dialog id="logoff-user-email" :md-active.sync="showLogoffDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="logoffPromptMessage" @noButtonClick="cancelLogoffClick()" @yesButtonClick="okLogoffClick()" />
    </md-dialog>

    <md-dialog id="apps-dialog" :md-active.sync="showAppsDialog" :md-click-outside-to-close="false">
      <div class="dialog-main-div">
        <div class="vasion-page-title top-div">
          <label>{{ appsDialogHeaderText }}</label>
        </div>
        <div class="field-selection-div">
          <VasionListSelection
            :available-fields.sync="availableApps"
            :selected-fields.sync="assignedApps"
            :allow-field-ordering="allowRoleOrdering"
            :available-label-text="'Defined Apps'"
            :selected-label-text="'Assigned Apps'"
            :unique-id="`list-of-${appsDialogHeaderText}`"
          />
        </div>
        <div class="bottom-div">
          {{ appErrorText }}
          <VasionButton id="cancel-apps-button" :classProp="'primary-light'" @vasionButtonClicked="cancelAppsClick()">
            CANCEL
          </VasionButton>
          <VasionButton id="ok-apps-button" :classProp="'primary'" @vasionButtonClicked="okAppsClick()">
            OK
          </VasionButton>
        </div>
      </div>
    </md-dialog>
    <md-dialog id="roles-dialog" :md-active.sync="showRolesDialog" :md-click-outside-to-close="false">
      <div class="dialog-main-div">
        <div class="vasion-page-title top-div">
          <label>{{ rolesDialogHeaderText }}</label>
        </div>
        <div class="field-selection-div">
          <VasionListSelection
            :available-fields.sync="availableRoles"
            :selected-fields.sync="assignedRoles"
            :allow-field-ordering="allowRoleOrdering"
            :available-label-text="'Defined Roles'"
            :selected-label-text="'Assigned Roles'"
            :unique-id="`list-of-${rolesDialogHeaderText}`"
          />
        </div>
        <div class="bottom-div">
          {{ roleErrorText }}
          <VasionButton id="cancel-roles-button" :classProp="'primary-light'" @vasionButtonClicked="cancelRolesClick()">
            CANCEL
          </VasionButton>
          <VasionButton
            v-if="!selectedUserIsAdmin"
            id="ok-roles-button"
            :classProp="'primary'"
            @vasionButtonClicked="okRolesClick()"
          >
            OK
          </VasionButton>
        </div>
      </div>
    </md-dialog>
    <md-dialog id="tokens-dialog" :md-active.sync="showTokenDialog" :md-click-outside-to-close="false">
      <ConfigureTokens
        :header-text="tokenHeaderText"
        :items-array="tokens"
        @ok-click="okTokenClick"
        @cancel-click="cancelTokenClick"
        @error="tokenError"
      />
    </md-dialog>
    <md-dialog id="default-password-dialog" :md-active.sync="showPasswordDialog" :md-click-outside-to-close="false">
      <PasswordPrompt
        inputTitle="Default Password"
        :defaultPasswordProp="defaultPassword"
        :requirePasswordEntry="false"
        @close="showPasswordDialog = false"
        @passwordEntered="defaultPasswordEntered"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import ConfigureTokens from '@/components/security/ConfigureTokens.vue';
import ConfigureUser from '@/components/security/ConfigureUser.vue';
import ImportLdapUsers from '@/components/security/ImportLdapUsers.vue';
import PasswordPrompt from '@/components/security/PasswordPrompt.vue'
import { tableSorter } from '@/store/helperModules/common.module'
import Loading from 'vue-loading-overlay';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'Users',
  components: {
    ConfigureTokens,
    ConfigureUser,
    ImportLdapUsers,
    Loading,
    PasswordPrompt,
  },
  data: function () {
    return {
      activeUserSearchInput: '',
      allowRoleOrdering: false,
      appErrorText: '',
      assignedApps: [],
      assignedRoles: [],
      availableApps: [],
      availableRoles: [],
      columns: {
        Values: [
          '_UserID_',
          'Username',
          'Full Name',
          'Email',
          'Active',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      columnsActive: {
        Values: [
          '_UserID_',
          'Username',
          'Full Name',
          'Instance',
          'Login Date',
          'Email',
          '_OriginalLoginDate_',
          '_VasionEllipsisButton_',
        ],
        ValueType: 'String',
      },
      currentUser: null,
      defaultPassword: '',
      emailAddress: '',
      externalUserSearchInput: '',
      hiddenColumns: ['_UserID_'],
      hiddenColumnsActive: ['_UserID_', '_OriginalLoginDate_'],
      hiddenColumnsExternal: ['_UserID_', 'Active', '_VasionEllipsisButton_'],
      isLoading: false,
      ldapServerIp: '',
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localActiveUserList: null,
      localUserList: null,
      logOffUserName: '',
      logOffInstance: '',
      logOffLoginDate: '',
      removingUser: null,
      roleErrorText: '',
      selectedTabName: 'internal users',
      selectedUserFullName: '',
      selectedUserID: 0,
      selectedUserIsAdmin: false,
      showAppsDialog: false,
      showConfigureUserDialog: false,
      showImportLdapUsersDialog: false,
      showDeleteDialog: false,
      showEmailDialog: false,
      showLogoffDialog: false,
      showPasswordDialog: false,
      showRolesDialog: false,
      showSnackbar: false,
      showTokenDialog: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortTableAscending: true,
      sortTableAscendingActive: true,
      sortTableBy: 'Username',
      sortTableByActive: 'Username',
      tableData: {},
      tableDataActive: {},
      tokens: [],
      usersLoading: true,
      UserSearchInput: '',
    }
  },
  computed: {
    activeUserList() { return this.$store.state.security.activeUserList },
    activeUsersTabClass() { return this.selectedTabName === 'active users' ? 'text-selected' : 'text' },
    apiToken() { return this.$store.state.common.apiToken },
    appsDialogHeaderText() {
      return this.selectedUserIsAdmin
        ? `${this.selectedUserFullName} - Cannot Modify Apps - Admin User`
        : `Assign Apps to ${this.selectedUserFullName}`
    },
    canConfigureUsers() { return this.$store.getters['common/canConfigureUsers'] },
    canExportUsers() { return this.isAdminUser || this.currentUserIsDepartmentAdmin },
    configureUserTitle() { return this.selectedUserID > 0 ? 'Edit User' : 'Create User' },
    currentUserIsDepartmentAdmin() { return this.currentUser?.adminForDepartments?.length > 0 && !this.currentUser?.admin },
    deletePromptMessage() { return `Are you sure you want to delete "${this.selectedUserFullName}"?` },
    emailPromptMessage() { return `Are you sure you want to send a log off request to "${this.selectedUserFullName}"?` },
    departmentList() { return this.$store.state.security.departmentList },
    ellipsisButtonConfig() { return this.$store.state.security.ellipsisButtonConfig },
    externalUserList() { return this.$store.state.security.externalUserList },
    externalUsersTabClass() { return this.selectedTabName === 'external users' ? 'text-selected' : 'text' },
    internalUsersTabClass() { return this.selectedTabName === 'internal users' ? 'text-selected' : 'text' },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    logoffPromptMessage() { return `Are you sure you want to log off "${this.selectedUserFullName}"?` },
    rolesDialogHeaderText() {
      return this.selectedUserIsAdmin
        ? `${this.selectedUserFullName} - Cannot Modify Roles - Admin User`
        : `Assign Roles to ${this.selectedUserFullName}`
    },
    tokenHeaderText() { return `Token Security - ${this.selectedUserFullName}` },
    userLicenseAvailable() {
      return this.$store.state.admin.licenseAvailable
    },
    userLicenseAvailableMessage() {
      return this.$store.state.admin.licenseAvailableMessage
    },
    userList() {
      return this.$store.state.security.userList.filter(user => {
        return !this.externalUserList.some(externalUser => {
          return externalUser.Username === user.Username
        })
      })
    },
  },
  async created() {
    await this.refreshData()
  },
  methods: {
    async activeUserSearchList(input) {
      this.localActiveUserList = await this.filteredValues(this.activeUserList, input)
      this.refreshTableActive(this.localActiveUserList)
    },
    async assignAppsClick(userID, userFullName, userIsAdmin) {
      this.selectedUserID = userID
      this.selectedUserFullName = userFullName
      this.selectedUserIsAdmin = userIsAdmin
      this.appErrorText = ''

      this.assignedApps = []
      this.availableApps = []

      const [usersCurrentApps, allApps] = await Promise.all([
        this.$store.dispatch('security/getUserApps', this.selectedUserID),
        this.$store.dispatch('security/getAllApps'),
      ])
      const remainingApps = allApps.Values.filter(app => usersCurrentApps.Values.findIndex(value => value.systemName === app.systemName) === -1)
      if (usersCurrentApps) {
        if (usersCurrentApps.Values) {
          usersCurrentApps.Values.forEach((element) => {
            this.assignedApps.push({
              value: element.systemName,
              name: element.displayName,
            })
          })
        }

        if (remainingApps) {
          remainingApps.forEach((element) => {
            this.availableApps.push({
              value: element.systemName,
              name: element.displayName,
            })
          })
        }
      }

      this.showAppsDialog = true
    },
    async assignRolesClick(userID, userFullName, userIsAdmin) {
      this.selectedUserID = userID
      this.selectedUserFullName = userFullName
      this.selectedUserIsAdmin = userIsAdmin
      this.roleErrorText = ''

      this.assignedRoles = []
      this.availableRoles = []

      const rolesResult = await this.$store.dispatch('security/getUserRoles', this.selectedUserID)
      if (rolesResult) {
        if (rolesResult.assignedRoles) {
          rolesResult.assignedRoles.forEach((element) => {
            this.assignedRoles.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }

        if (rolesResult.availableRoles) {
          rolesResult.availableRoles.forEach((element) => {
            this.availableRoles.push({
              value: element.iID,
              name: element.sName,
            })
          })
        }
      }

      this.showRolesDialog = true
    },
    cancelAppsClick() { this.showAppsDialog = false },
    cancelConfigureUserClick() { this.showConfigureUserDialog = false },
    cancelDeleteClick() { this.showDeleteDialog = false },
    cancelEmailClick() { this.showEmailDialog = false },
    cancelLogoffClick() { this.showLogoffDialog = false },
    cancelRolesClick() { this.showRolesDialog = false },
    cancelTokenClick() { this.showTokenDialog = false },
    checkUserLimit() {
      if (this.userLicenseAvailable) {
        this.editUserClick(0)
      } else {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'User License Error'
        this.snackbarSubTitle = `${this.userLicenseAvailableMessage}`
      }
    },
    defaultPasswordEntered(newPassword) {
      const payload = {
        sConfigName: 'ChangePasswordValue',
        sConfigValue: newPassword,
      }

      this.$store.dispatch('common/setConfig', payload)
      this.showPasswordDialog = false

      this.showSnackbar = true
      this.snackbarImage = true
      this.snackbarTitle = 'SUCCESS'
      this.snackbarSubTitle = 'Default Temp Password saved successfully.'
    },
    async deleteUserClick(userID, userFullName) {
      if (this.selectedUserID != userID) {
        this.removingUser = await this.$store.dispatch('security/getUserDetails', userID)
      }
      if (this.currentUserIsDepartmentAdmin &&
        this.currentUser.adminForDepartments.find(element => element === this.removingUser.department) === undefined) {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'ERROR'
        this.snackbarSubTitle = 'Can not delete a user from a different department.'
        return
      }
      this.selectedUserID = userID
      this.selectedUserFullName = userFullName
      this.showDeleteDialog = true
    },
    editUserClick(userID) {
      this.selectedUserID = userID
      this.showConfigureUserDialog = true
    },
    ellipsisButtonClicked(payload) {
      if (payload?.item?.Values[0]) {
        const userId = payload.item.Values[0]
        const user = this.localUserList.find(u => u.UserId === userId)

        switch (payload.buttonId) {
          case 'assign-app-security-button':
            this.assignAppsClick(user.UserId, user.FullName, user.Admin)
            break;
          case 'assign-roles-button':
            this.assignRolesClick(user.UserId, user.FullName, user.Admin)
            break;
          case 'delete-user-button':
            this.deleteUserClick(user.UserId, user.FullName)
            break;
          case 'edit-user-button':
            this.editUserClick(userId)
            break;
          case 'token-security-button':
            this.tokenSecurityClick(user.UserId, user.FullName)
            break;
          default:
            break;
        }
      }
    },
    ellipsisButtonClickedActive(payload) {
      switch (payload.buttonId) {
        case 'email-user-button':
          this.emailUserClick(payload?.item?.Values[2], payload?.item?.Values[5])
          break;
        case 'logoff-user-button':
          this.logoffUserClick(payload?.item?.Values[2], payload?.item?.Values[1], payload?.item?.Values[3], payload?.item?.Values[6])
          break;
      }
    },
    async emailUserClick(fullName, emailAddress) {
      if (!emailAddress) {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'The selected user does not have an email address defined.'
        return
      }

      this.selectedUserFullName = fullName
      this.emailAddress = emailAddress
      this.showEmailDialog = true
    },
    exportUsersList(fileType) {
      let reportType = ''
      switch (this.selectedTabName) {
        case 'internal users':
          reportType = 'INTERNAL'
          break;
        case 'external users':
          reportType = 'EXTERNAL'
          break;
        case 'active users':
          reportType = 'ACTIVE'
          break;
        default:
          reportType = null
          break;
      }

      const payload = {
        "reportType": reportType,
        "outputFormat": fileType
      }

      this.$store.dispatch('security/downloadUsersList', payload)
    },
    async filteredValues(users, text) {
      const input = text.toLowerCase()
      if (!input) { return users }

      const returnList = users.filter(user => {
        const userNameTarget = user.Username.toLowerCase()
        const foundUserName = userNameTarget.indexOf(input) > -1

        const fullNameTarget = user.FullName.toLowerCase()
        const foundFullName = fullNameTarget.indexOf(input) > -1

        const emailTarget = user.Email?.toLowerCase()
        const foundEmail = emailTarget?.indexOf(input) > -1

        return foundUserName || foundFullName || foundEmail
      })

      return returnList
    },
    async loadActiveUsers() {
      await this.$store.dispatch('security/getActiveUsers')
      this.refreshTableActive()
    },
    ldapUsersSuccessfullyImported() {
      this.showImportLdapUsersDialog = false
      this.showSnackbar = true
      this.snackbarImage = true
      this.snackbarTitle = 'Success'
      this.snackbarSubTitle = 'LDAP Users Successfully Imported.'
      this.refreshData()
    },
    async logoffUserClick(fullName, userName, instance, loginDate) {
      let errorMessage = ''
      if (userName == this.currentUser?.userName && instance == 'Vasion Cloud') {
        errorMessage = 'The selected user session is your current session. You cannot log off this user session.'
      } else if (!userName || !instance || !loginDate) {
        errorMessage = 'The selected user session does not have an authorization token. You cannot log off this user session.'
      }

      if (errorMessage !== '') {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = errorMessage
        return
      }

      this.selectedUserFullName = fullName
      this.logOffUserName = userName
      this.logOffInstance = instance
      this.logOffLoginDate = loginDate
      this.showLogoffDialog = true
    },
    async okAppsClick() {
      this.appErrorText = ''

      const rolePayload = {
        entityID: this.selectedUserID,
        appSystemNames: this.assignedApps ? this.assignedApps.map((role) => role.value) : [],
      }

      const result = await this.$store.dispatch('security/saveUserApps', rolePayload)

      if (result && result === 'True') {
        this.showAppsDialog = false
      } else {
        this.appErrorText = 'Unable to Save User App Access'
      }
    },
    async okConfigureUserClick() {
      this.showConfigureUserDialog = false
      this.isLoading = true
      await Promise.all([
        this.$store.dispatch('security/loadExternalUsersList'),
        this.$store.dispatch('security/loadUserList'),
      ])

      this.showSnackbar = true
      this.snackbarImage = true
      this.snackbarTitle = 'Success'
      this.snackbarSubTitle = 'User was successfully saved.'

      if (this.UserSearchInput) {
        this.userSearchList(this.UserSearchInput)
      } else if (this.externalUserSearchInput) {
        this.userSearchList(this.externalUserSearchInput)
      } else {
        this.refreshTable()
      }
    },
    async okDeleteClick() {
      this.showDeleteDialog = false

      if (this.selectedUserID > 0) {
        const result = await this.$store.dispatch('security/deleteUser', this.selectedUserID)
        if (result && result.Value === 'True') {
          this.isLoading = true

          await Promise.all([
            this.$store.dispatch('security/loadExternalUsersList'),
            this.$store.dispatch('security/loadUserList'),
          ])

          this.showSnackbar = true
          this.snackbarImage = true
          this.snackbarTitle = 'User Successfully Deleted'
          this.snackbarSubTitle = `Deleted user "${this.selectedUserFullName}"`

          if (this.UserSearchInput) {
            this.userSearchList(this.UserSearchInput)
          } else if (this.externalUserSearchInput) {
            this.userSearchList(this.externalUserSearchInput)
          } else {
            this.refreshTable()
          }
        } else {
          this.snackbarTitle = 'User Not Deleted'
          this.snackbarSubTitle = `Unable to delete user "${this.selectedUserFullName}"`
          this.snackbarImage = false
          this.showSnackbar = true
        }
      }

      this.selectedUserID = 0
      this.selectedUserFullName = ''
    },
    async okEmailClick() {
      const success = await this.$store.dispatch('security/sendLogOffEmail', this.emailAddress)
      this.showSnackbar = true
      this.snackbarImage = success
      this.snackbarTitle = success ? 'Success' : 'Error'
      this.snackbarSubTitle = success ? 'Logoff Email successfully sent.' : 'Error sending Logoff email.'
      this.showEmailDialog = false
    },
    async okLogoffClick() {
      const payload = {
        userName: this.logOffUserName,
        instance: this.logOffInstance,
        loginDate: this.logOffLoginDate,
      }
      const success = await this.$store.dispatch('security/forceLogOff', payload)
      this.showSnackbar = true
      this.snackbarImage = success
      this.snackbarTitle = success ? 'Success' : 'Error'
      this.snackbarSubTitle = success ? 'User successfully logged off.' : 'Error logging off user.'
      this.showLogoffDialog = false
      this.loadActiveUsers()
    },
    async okRolesClick() {
      this.roleErrorText = ''

      const rolePayload = {
        entityID: this.selectedUserID,
        roleIDs: this.assignedRoles ? this.assignedRoles.map((role) => role.value) : [],
      }

      const result = await this.$store.dispatch('security/saveUserRoles', rolePayload)

      if (result && result === 'True') {
        this.showRolesDialog = false
      } else {
        this.roleErrorText = 'Unable to Save User Roles'
      }
    },
    async okTokenClick(selectedTokens) {
      const saveTokensPayload = {
        entityID: this.selectedUserID,
        tokens: selectedTokens,
      }

      const saveResult = await this.$store.dispatch('security/saveUserTokens', saveTokensPayload)

      if (saveResult && saveResult === 'True') {
        this.showSnackbar = true
        this.snackbarImage = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = `User Tokens for '${this.selectedUserFullName}' were successfully saved.`

        this.showTokenDialog = false
      } else {
        this.showSnackbar = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Unable to save User Tokens.'
      }
    },
    async refreshData() {
      this.usersLoading = true
      this.isLoading = true

      const promise1 = this.$store.dispatch('security/loadUserList')
      const promise2 = this.$store.dispatch('security/loadExternalUsersList')
      const promise3 = this.$store.dispatch('admin/getLicenseAvailabilityForUserUpsert')
      const getDepartments = this.$store.dispatch('security/loadDepartmentList')
      const getLdapServerIp = this.$store.dispatch('security/getLdapServerIp')
      const getCurrentUser = this.$store.dispatch('security/getUserDetails', this.$store.state.common.userID)

      const promisesV1 = await Promise.all([promise1, promise2, promise3, getDepartments, getCurrentUser])
      this.currentUser = promisesV1[4]

      this.$store.dispatch('security/loadLoginTypeList')
      const promisesV2 = await Promise.all([getLdapServerIp])
      const serverIpResponse = promisesV2[1]
      if (serverIpResponse?.Value?.length > 0) {
        this.ldapServerIp = serverIpResponse.Value
      }
      this.usersLoading = false
      this.isLoading = false
      this.refreshTable()
    },
    async refreshTable(list) {
      if (list) {
        this.localUserList = list
      } else if (this.selectedTabName === 'external users') {
        this.localUserList = this.externalUserList
      } else {
        this.localUserList = this.userList
      }
      const data = {
        dataList: this.localUserList,
        columns: this.columns,
      }
      this.tableData = await this.$store.dispatch('security/setUserTableData', data)
      await this.$store.dispatch('security/setEllipsisButtonConfig', !this.currentUserIsDepartmentAdmin ? 'user-buttons' : 'user-buttons-department-admins')
      this.isLoading = false
    },
    async refreshTableActive(list) {
      if (list) {
        this.localActiveUserList = list
      } else {
        this.localActiveUserList = this.activeUserList
      }
      const data = {
        dataList: this.localActiveUserList,
        columns: this.columnsActive,
      }
      this.tableDataActive = await this.$store.dispatch('security/setActiveUserTableData', data)
      await this.$store.dispatch('security/setEllipsisButtonConfig', 'active-user-buttons')
      this.isLoading = false
    },
    rowClickHandler(data) {
      if (!data || !data.Values || data.Values.length <= 0) return
      this.editUserClick(data.Values[0])
    },
    async showDefaultPasswordPrompt() {
      this.defaultPassword = await this.$store.dispatch('common/getConfig', 'ChangePasswordValue')
      this.showPasswordDialog = true
    },
    async sortColumns(options) {
      this.isLoading = true
      this.sortTableAscending = options.ascending
      switch (options.columnName) {
        case 'Username':
          this.sortTableBy = 'Username'
          break
        case 'Full Name':
          this.sortTableBy = 'FullName'
          break
        case 'Email':
          this.sortTableBy = 'Email'
          break
        case 'Active':
          this.sortTableBy = 'Active'
          break
        default:
          break
      }
      this.localUserList.sort(tableSorter(this.sortTableBy, this.sortTableAscending))
      this.refreshTable()
    },
    async sortColumnsActive(options) {
      this.isLoading = true
      this.sortTableAscendingActive = options.ascending
      switch (options.columnName) {
        case 'Username':
          this.sortTableByActive = 'Username'
          break
        case 'Full Name':
          this.sortTableByActive = 'FullName'
          break
        case 'Email':
          this.sortTableByActive = 'Email'
          break
        case 'Instance':
          this.sortTableByActive = 'Instance'
          break
        case 'Login Date':
          this.sortTableByActive = 'LoginDate'
          break
        default:
          break
      }
      this.localActiveUserList.sort(tableSorter(this.sortTableByActive, this.sortTableAscendingActive))
      this.refreshTableActive()
    },
    tokenError(message) {
      this.showSnackbar = true
      this.snackbarImage = false
      this.snackbarTitle = 'Error'
      if (message) {
        this.snackbarSubTitle = message
      } else {
        this.snackbarSubTitle = "Error configuring Token Security."
      }
    },
    async tokenSecurityClick(userID, userFullName) {
      this.selectedUserID = userID
      this.selectedUserFullName = userFullName
      this.tokens = []

      const tokensResult = await this.$store.dispatch('security/getUserTokens', this.selectedUserID)
      if (tokensResult && tokensResult.fields) {
        tokensResult.fields.forEach((element) => {
          this.tokens.push(element)
        })
      }

      this.showTokenDialog = true
    },
    updateSelectedTab(tabName) {
      this.UserSearchInput = ''
      this.externalUserSearchInput = ''
      this.selectedTabName = tabName
      if (tabName === 'active users') {
        this.loadActiveUsers()
      }
      else {
        this.refreshTable()
      }
    },
    async userSearchList(input) {
      if (this.selectedTabName === 'external users') {
        this.localUserList = this.externalUserList
      } else {
        this.localUserList = this.userList
      }
      if (input) {
        if (this.selectedTabName === 'external users') {
          this.localUserList = await this.filteredValues(this.externalUserList, input)
        } else {
          this.localUserList = await this.filteredValues(this.userList, input)
        }
      }

      this.refreshTable(this.localUserList)
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/btn-styles.scss';

  #external-users-search-row {
    margin-bottom: 14px;
  }

  .tab-button-div {
    margin-left: -8px;
  }

  .top-button-position {
    margin-top: -4px;
  }

  #roles-dialog,
  #apps-dialog {
    width: 60%;
    height: 70%;
    min-width: 1000px;
  }

  #tokens-dialog {
    height: 500px;
    width: 800px;
  }

  .grid-div {
    height: calc(100vh - 336px);
    overflow: auto;
  }

  .dialog-main-div {
    width: 100%;
    height: 100%;
    margin: 32px 24px 32px 24px;
  }

  .top-div {
    height: 50px;
  }

  .field-selection-div {
    height: calc(100% - 132px);
    width: calc(100% - 48px);
  }

  .bottom-div {
    position: absolute;
    bottom: 12px;
    width: calc(100% - 48px);
    text-align: right;
    vertical-align: middle;
    line-height: 50px;
  }

  .search-row {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dropdown-list-container {
    margin-top: 4px;
  }

  .dropdown-list-container,
  .dropdown-list-container ::v-deep .md-menu-content-container {
    border-radius: 8px;
  }

  .dropdown-list-container ::v-deep .md-list {
    padding: 0px;
  }

  .dropdown-list-container ::v-deep button.md-list-item-button:hover {
    background-color: $grey-75 !important;
  }

  .export-margin {
    margin-left: 8px;
  }

  .users-table-container {
    height: calc(100vh - 336px);
    overflow: auto;
    text-align: left;
  }

  .md-menu-export {
    padding-top:2px;
  }
</style>
