<template>
  <div class="main-container">
    <div class="header-row">
      <VasionInput
        v-model="serverIP"
        title="LDAP SERVER IP"
        class="input-field"
        inputType="top-white"
        placeholder="Enter LDAP Server IP..."
        required
        :isInErrorState="null"
        @input="null"
      />
      <VasionButton
        id="read-ldap-btn"
        :classProp="'secondary-grey'"
        @vasionButtonClicked="getLdapUsers">
          Read LDAP
      </VasionButton>
      <VasionInput
        v-model="ldapFilter"
        title="LDAP FILTER"
        class="input-field"
        inputType="top-white"
        placeholder="Enter LDAP Filter..."
        :isInErrorState="null"
        @input="null"
      />
    </div>

    <VasionTable
      class="ldap-users-table"
      v-if="tableRows.length > 0"
      :headerColumns="columnHeaders"
      :tableRows="tableRows"
      :supportSorting="true"
      :hideColumns="hiddenColumns"
      @vasion-column-sort="sortTable"
      @vasion-row-checked="rowsChecked"
    />

    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      loader="dots"
      :color="loaderColor"
      :background-color="loaderBackgroundColor"
    />

    <div class="footer-row" v-show="tableRows.length > 0">
      <VasionButton
        id="cancel-btn"
        :classProp="'secondary-grey'"
        @vasionButtonClicked="cancelClicked">
          Cancel
      </VasionButton>
      <VasionButton
        id="submit-btn"
        :classProp="'primary'"
        :isDisabled="selectedUserCount === 0"
        @vasionButtonClicked="submit">
          Import
      </VasionButton>
    </div>

    <VasionSnackbar
      id="results-snack"
      :showSnackbarBool.sync="showSnackbar"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

  </div>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import Loading from 'vue-loading-overlay';
import { uuid } from 'vue-uuid';

export default {
  name: 'ImportLdapUsers',
  components: {
    Loading
  },
  data() {
    return {
      serverIP: '',
      showSnackbar: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortByField: '',
      ldapFilter: '',
      ldapUsers: new Map(),
      licenseInfo: null,
      columnHeaders: ['_VasionCheckBox_', 'Username', 'Full Name', 'Email', 'Mobile', 'Group', 'ID'],
      hiddenColumns: ['ID'],
      tableRows: [],
      isLoading: false
    }
  },
  async created() {
    const getLdapServerIp = this.$store.dispatch('security/getLdapServerIp')
    this.licenseInfo = await this.$store.dispatch('admin/getLicenseAvailabilityForUserUpsert')
    const serverIpResponse = await getLdapServerIp
    if(serverIpResponse?.Value?.length > 0){
      this.serverIP = serverIpResponse.Value
    }
  },
  computed: {
    loaderBackgroundColor() { return loaderBackgroundColor },
    loaderColor() { return loaderColor },
    selectedUserCount() {
      let count = 0;
      for (let user of this.ldapUsers.values()) {
        if (user.isSelected) count++
      }
      return count
    }
  },
  methods: {
    cancelClicked(){
      this.$emit('cancelBtnClick')
    },
    displaySnackbar(success, title, subtitle) {
      this.snackbarImage = success
      this.snackbarTitle = title
      this.snackbarSubTitle = subtitle
      this.showSnackbar = true
    },
    async getLdapUsers(){
      try{
        if(this.serverIP.length === 0) {
          this.displaySnackbar(false, 'Warning', 'Please enter the LDAP Server IP')
          return
        }
        this.isLoading = true
        const response = await this.$store.dispatch('security/getLdapUserList', {
          server: this.serverIP,
          filter: this.ldapFilter,
         })

        if(response.Value || !response.users){
          this.displaySnackbar(false, 'Error', response.Value ? response.Value : "Could not get LDAP users")
          this.isLoading = false
          return
        }
        let usersMap = new Map();
        response.users.forEach(u => {
          u.isSelected = false
          u.id = uuid.v1().toString()
          usersMap.set(u.id, u)
        });
        this.ldapUsers = usersMap
        this.updateTableRows(usersMap)
        this.isLoading = false
      }
      catch{
        this.displaySnackbar(false, 'Error', "Unable to fetch LDAP users")
        this.isLoading = false
      }
    },
    rowsChecked(rows){
      const idx_isSelected = 0
      const idx_id = 6
      const selectedCount = this.selectedUserCount
      const licensedUserCount = this.licenseInfo.ActiveUserCount + selectedCount + rows.length
      const totalLicenseCount = this.licenseInfo.LicenseCount
      const isConcurrentLicenseModel = this.licenseInfo.LicenseType === 0
      const clonedLdapUsers = cloneDeep(this.ldapUsers)

      if(rows.length === 0) {
        // un-checked 1 or more users
        this.tableRows.forEach(row => {
          const user = clonedLdapUsers.get(row.Values[idx_id])
          user.isSelected = row.Values[idx_isSelected].value
        })
      }
      else if(isConcurrentLicenseModel || licensedUserCount <= totalLicenseCount) {
        rows.forEach(row => {
          const user = clonedLdapUsers.get(row.Values[idx_id])
          user.isSelected = true
        })
      }
      else {
        const remainingLicenseCount = totalLicenseCount - selectedCount - this.licenseInfo.ActiveUserCount
        const warningMsg = remainingLicenseCount > 0 ?
                            `Not enough licenses available (${remainingLicenseCount} remaining)` :
                            'No more licenses available'
        this.displaySnackbar(false, 'Warning', warningMsg)
      }
      this.ldapUsers = clonedLdapUsers
      this.updateTableRows(clonedLdapUsers)
    },
    async submit() {
      this.isLoading = true
      try {
        const payload = { users: [] }
        for (let user of this.ldapUsers.values()){
          if(user.isSelected) {
            payload.users.push({
              name : user.name,
              displayName: user.displayName,
              email: user.email,
              mobile: user.mobile,
              group: user.group,
            })
          }
        }
        const errorResponse = await this.$store.dispatch('security/importLdapUsers', payload)
        if(errorResponse?.Value.length > 0){
          this.displaySnackbar(false, 'Error', errorResponse?.Value)
        }
        else{
          this.$emit('successfulLdapUserImport')
        }
        this.isLoading = false
      }
      catch {
        this.isLoading = false
        this.displaySnackbar(false, 'Error', 'Error fetching LDAP users')
      }
    },
    sortTable(options) {
      const columnIdx = this.columnHeaders.indexOf(options.columnName)
      if(columnIdx <= 0) {
        return
      }

      const clonedTableData = cloneDeep(this.tableRows)
      clonedTableData.sort((a, b) => {
          const item1 = a.Values[columnIdx]
          const item2 = b.Values[columnIdx]
          if (!options.ascending) {
            return (item1).localeCompare(item2)
          }
          return (item2).localeCompare(item1)
        }
      )
      this.tableRows = clonedTableData
    },
    updateTableRows(rowsMap) {
      const getDisplayValue = x => x?.length > 0 ? x : '-'
      let newTableRows = []
      for (let user of rowsMap.values()) {
        newTableRows.push({
              Values: [
                { show: true, value: user.isSelected },
                getDisplayValue(user.name),
                getDisplayValue(user.displayName),
                getDisplayValue(user.email),
                getDisplayValue(user.mobile),
                getDisplayValue(user.group),
                user.id
              ],
              ValueType: 'String',
            })
      }
      this.tableRows = newTableRows
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-container {
    min-width: 600px;
  }
  .header-row {
    margin-top: 1rem;
    display: flex;
    align-items: flex-end;
  }

  .footer-row {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    overflow-x: hidden;
  }

  .input-field {
    flex-grow: 1;
  }

  #read-ldap-btn {
    margin-right: 1rem;
  }

  .table-container {
    overflow-x: scroll;
  }

  .ldap-users-table {
    margin-top: 2rem;
    max-height: 50vh;
    overflow-y: scroll;
  }
</style>
